.graph-stats td:first-child {
  font-weight: bold;
}
.graph-stats a:visited {
  color: blue;
}

.table-period-amount td:nth-child(2) {
  text-align: right;
}

.loading {
  opacity: 0.5;
  pointer-events: none;
}

.card-body {
  overflow-x: auto;
}
